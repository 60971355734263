import AOS from 'aos';

/**
 * Update delays
 */

const updateDelays = items => {
  $(items).each(function(idx) {
    const delay = $(this).data('aos-delay');

    $(this).attr('data-aos-delay', delay * idx);
    
  })
}

updateDelays('.list-locations__item');
updateDelays('.list-benefits__item');
updateDelays('.list-contacts__item');
updateDelays('.list-reviews__item');

AOS.init();